<template>
    <div class="history-block block">
        <div class="block-title">
            <div class="dot" />
            {{ $t('invention.history.title') }}
        </div>
        <div class="panels">
            <v-expansion-panels accordion>
                <v-expansion-panel
                    v-for="(annual, i) in historyTeam"
                    :key="i"
                    class="panel-item"
                >
                    <v-expansion-panel-header class="annual-title">
                        {{ annual.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="annual-content">
                        <div
                            v-if="annual.college[0].length>0"
                            class="stage college"
                        >
                            <div class="stage-title">
                                {{ $t('invention.history.stage[0]') }}
                            </div>
                            <div class="mobile-teams">
                                <v-carousel
                                    hide-delimiters
                                    height="347"
                                >
                                    <v-carousel-item
                                        v-for="(team, index) in annual.college[0]"
                                        :key="index"
                                        class="team-item"
                                        @click="handleClickItem(team)"
                                    >
                                        <div class="box">
                                            <div
                                                v-if="team.rank"
                                                class="rank"
                                            >
                                                {{ team.rank }}
                                            </div>
                                            <img
                                                :src="image(team.image)"
                                                alt=""
                                            >
                                        </div>
                                        <div class="team-title">
                                            {{ team.title }}
                                        </div>
                                        <div class="team-name">
                                            {{ team.name }}
                                        </div>
                                    </v-carousel-item>
                                </v-carousel>
                            </div>
                            <div class="teams desktop-teams">
                                <div
                                    v-for="(team, index) in annual.college[0]"
                                    :key="index"
                                    class="team-item"
                                    @click="handleClickItem(team)"
                                >
                                    <div class="box">
                                        <div
                                            v-if="team.rank"
                                            class="rank"
                                        >
                                            {{ team.rank }}
                                        </div>
                                        <img
                                            :src="image(team.image)"
                                            alt=""
                                        >
                                    </div>
                                    <div class="team-title">
                                        {{ team.title }}
                                    </div>
                                    <div class="team-name">
                                        {{ team.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="annual.senior[0].length>0"
                            class="stage hight-school"
                        >
                            <div class="stage-title">
                                {{ $t('invention.history.stage[1]') }}
                            </div>
                            <div class="mobile-teams">
                                <v-carousel
                                    hide-delimiters
                                    height="347"
                                >
                                    <v-carousel-item
                                        v-for="(team, index) in annual.senior[0]"
                                        :key="index"
                                        class="team-item"
                                        @click="handleClickItem(team)"
                                    >
                                        <div class="box">
                                            <div
                                                v-if="team.rank"
                                                class="rank"
                                            >
                                                {{ team.rank }}
                                            </div>
                                            <img
                                                :src="image(team.image)"
                                                alt=""
                                            >
                                        </div>
                                        <div class="team-title">
                                            {{ team.title }}
                                        </div>
                                        <div class="team-name">
                                            {{ team.name }}
                                        </div>
                                    </v-carousel-item>
                                </v-carousel>
                            </div>
                            <div class="teams desktop-teams">
                                <div
                                    v-for="(team, index) in annual.senior[0]"
                                    :key="index"
                                    class="team-item"
                                    @click="handleClickItem(team)"
                                >
                                    <div class="box">
                                        <div
                                            v-if="team.rank"
                                            class="rank"
                                        >
                                            {{ team.rank }}
                                        </div>
                                        <img
                                            :src="image(team.image)"
                                            alt=""
                                        >
                                    </div>
                                    <div class="team-title">
                                        {{ team.title }}
                                    </div>
                                    <div class="team-name">
                                        {{ team.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <TeamDialog
            v-if="dialogShow"
            :dialog-data="dialogData"
            @close="closeTeamDialog"
        />
    </div>
</template>

<script>
import TeamDialog from './TeamDialog.vue';

export default {
    components: {
        TeamDialog,
    },
    props: {
        historyTeam: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dialogShow: false,
            dialogData: {
                rank: '',
                image: '',
                title: '',
                name: '',
                selfTheme: '',
                shareTheme: '',
                content: '',
                fb: '',
                ig: '',
                youtube: '',
                mail: '',
            },
        };
    },
    computed: {},
    created() { },
    mounted() {
    },
    methods: {
        handleClickItem(team) {
            this.dialogShow = true;
            this.dialogData = team;
        },
        closeTeamDialog() {
            this.dialogShow = false;
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.history-block {
  .mobile-teams {
    display: none;
  }

  .panel-item {
    .annual-title {
      font-weight: 700;
      font-size: 28px;
      line-height: 1;
      letter-spacing: 0.1em;
      color: $color-blue;
      text-align: center;
    }

    .stage {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .college {
      border-bottom: 1px solid $color-orange;
    }
    .stage-title {
      font-weight: 700;
      font-size: 21px;
      line-height: 1;
      letter-spacing: 0.1em;
      color: $color-blue;
      padding-left: 17px;
      border-left: 4px solid $color-blue;
      margin-bottom: 30px;
    }

    .teams {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 17px;
      justify-content: space-between;
    }

    .teams::after {
      content: '';
      height: 0;
      width: 280px;
    }

    .team-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      cursor: pointer;
      width: 280px;

      .box {
        position: relative;
        width: 280px;
        height: 280px;
        overflow: hidden;
        margin-bottom: 15px;
        .rank {
          background-color: $color-orange;
          border-radius: 1px;
          font-weight: 700;
          font-size: 18px;
          line-height: 1;
          letter-spacing: 0.1em;
          color: white;
          position: absolute;
          top: 0;
          height: 0;
          width: 79px;
          height: 29px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 100%;
        }
      }

      .team-title {
        font-weight: 700;
        font-size: 21px;
        line-height: 2;
        letter-spacing: 0.1em;
        color: $color-blue;
        text-align: center;
        margin-bottom: 15px;
        word-break: break-word;
      }
      .team-name {
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.1em;
        color: $color-blue;
        text-align: center;
      }
    }
  }

  .v-expansion-panel-header__icon {
    position: absolute;
    right: 0;
    .v-icon {
      color: $color-orange !important;
    }
  }
}

@media screen and (max-width: 760px) {
  .history-block {
    .mobile-teams {
      display: flex;
    }
    .desktop-teams {
      display: none !important;
    }

    .panel-item {
      .team-item {
        display: flex;
        align-items: center;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .history-block {
    .panel-item {
      .annual-title {
        font-size: 21px;
      }

      .stage{
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .stage-title {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
