<template>
    <div class="invention page normal-page">
        <div class="page-banner">
            <img
                class="desktop-banner"
                :src="image(content.imageDes)"
                alt=""
            >
            <img
                class="mobile-banner"
                :src="image(content.imageMob)"
                alt=""
            >
        </div>
        <div class="web-content">
            <div class="page-structure">
                <PageMenu :menu-data="pageMenu" />
                <div class="page-content column-center">
                    <Anchor :id="'explain'" />
                    <ExplainBlock
                        :content="content"
                    />
                    <Anchor :id="'registration'" />
                    <RegistrationBlock
                        :content="content"
                        :prize-list="prize"
                    />
                    <Anchor :id="'q-and-a'" />
                    <QandABlock
                        :question="qa"
                    />
                    <Anchor :id="'recommend'" />
                    <RecommendBlock
                        :recommend="recommend"
                    />
                    <Anchor :id="'history-team'" />
                    <HistoryBlock
                        :history-team="team"
                    />
                    <div
                        class="sign-up-btn"
                        @click="goToSignUp"
                    >
                        {{ $t('invention.goToSignUp') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import ExplainBlock from '@/components/invention/ExplainBlock.vue';
import RegistrationBlock from '@/components/invention/RegistrationBlock.vue';
import QandABlock from '@/components/invention/QandABlock.vue';
import RecommendBlock from '@/components/invention/RecommendBlock.vue';
import HistoryBlock from '@/components/invention/HistoryBlock.vue';
import Nzh from 'nzh';
import PageMenu from '../components/PageMenu.vue';
import 'nzh/hk';

export default {
    components: {
        PageMenu,
        ExplainBlock,
        RegistrationBlock,
        QandABlock,
        RecommendBlock,
        HistoryBlock,

    },
    data() {
        return {
            content: {},
            prize: null,
            qa: null,
            recommend: null,
            team: null,
            pageMenu: {
                // eslint-disable-next-line global-require
                icon: require('../assets/invention/menu-icon.png'),
                title: 'invention.pageMenu.title',
                button: {
                    text: 'invention.pageMenu.button',
                    routerTo: '/invention-apply/team-data/new',
                },
                items: [
                    {
                        type: 'link',
                        text: 'invention.pageMenu.items.item1.text',
                        link: '#explain',
                    },
                    {
                        type: 'panel',
                        text: 'invention.pageMenu.items.item2.text',
                        blockItems: [
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item2.blockItems[0]',
                                link: '#registration',
                            },
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item2.blockItems[1]',
                                link: '#judge-method',
                            },
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item2.blockItems[2]',
                                link: '#schedule',
                            },
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item2.blockItems[3]',
                                link: '#prize',
                            },
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item2.blockItems[4]',
                                target: '_blank',
                                link: '',
                            },
                        ],
                    },
                    {
                        type: 'panel',
                        text: 'invention.pageMenu.items.item3.text',
                        blockItems: [
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item3.blockItems[0]',
                                target: '_blank',
                                link: '',
                            },
                            {
                                type: 'link',
                                text: 'invention.pageMenu.items.item3.blockItems[1]',
                                target: '_blank',
                                link: '',
                            },
                        ],
                    },
                    {
                        type: 'link',
                        text: 'invention.pageMenu.items.item4.text',
                        link: '#q-and-a',
                    },
                    {
                        type: 'link',
                        text: 'invention.pageMenu.items.item5.text',
                        link: '#recommend',
                    },
                    {
                        type: 'link',
                        text: 'invention.pageMenu.items.item6.text',
                        link: '#history-team',
                    },
                ],
            },
        };
    },
    computed: {},
    watch: {
        $route() {
            this.init();
        },
    },
    created() { },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        async init() {
            try {
                const res = await API.InventionContent.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.pageMenu.items[2].blockItems[0].link = res.data.raw[0].universityDoc;
                this.pageMenu.items[2].blockItems[1].link = res.data.raw[0].heighSchoolDoc;
                this.pageMenu.items[1].blockItems[4].link = res.data.raw[0].planExample;
                // eslint-disable-next-line prefer-destructuring
                this.content = res.data.raw[0];
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.InventionPrize.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.prize = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.InventionQA.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.qa = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.InventionRecommend.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.recommend = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const historyTeam = [];
                const res = await API.InventionTeam.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                const session = [];
                for (let i = 0; i < res.data.raw.length; i += 1) {
                    if (!session.includes(res.data.raw[i].session)) {
                        session.push(res.data.raw[i].session);
                        session.sort((a, b) => a - b);
                    }
                }
                for (let i = 0; i < session.length; i += 1) {
                    const nzhcn = Nzh.hk;
                    let number = `${session[i]} Session`;
                    if (this.$route.path.split('/')[1] === 'zhTw') {
                        number = `第${nzhcn.encodeS(session[i])}屆`;
                    }
                    historyTeam.push({
                        number: session[i],
                        title: number,
                        college: [
                            res.data.raw.filter(
                                (value) => value.session === session[i],
                            ).filter(
                                (value) => value.type === 'college',
                            ),
                        ],
                        senior: [
                            res.data.raw.filter(
                                (value) => value.session === session[i],
                            ).filter(
                                (value) => value.type === 'senior',
                            ),
                        ],
                    });
                    historyTeam.sort(this.asort_json_num('number'));
                    this.team = historyTeam;
                }
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
        },
        goToSignUp() {
            const language = this.$route.path.split('/')[1];
            if (language === 'zhTw' || language === 'en') {
                this.$router.push(`/${language}/invention-apply/team-data/new`);
            } else {
                this.$router.push('/zhTw/invention-apply/team-data/new');
            }
        },
        asort_json_num(property) {
            return (a, b) => b[property] - a[property];
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.invention {
  .page-banner{
    width: 100vw;
    height: 300/1440*100vw;
    overflow: hidden;
    img{
        width: 100vw;
        object-fit: contain;
    }
  }

  .block-content {
    padding-left: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 2;
    text-align: justify;
    letter-spacing: 0.1em;
    color: $color-gray;
  }

  .sign-up-btn {
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    letter-spacing: 0.3em;
    color: white;
    background-color: $color-orange;
    border-radius: 5px;
    width: 75%;
    padding: 20px;
    text-align: center;
    margin-bottom: 80px;
    cursor: pointer;
  }
}

@media screen and (max-width: 960px) {
  .invention {
    .page-banner{
        height: 200/375*100vw;
    }
    .sign-up-btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .invention {
    .sub-block {
      .sub-title {
        font-size: 16px;

        .title-tips {
          font-size: 12px;
        }
      }
    }

    .block-content {
      font-size: 14px;
    }
    .sign-up-btn {
      font-size: 18px;

    }
  }
}

@media screen and (max-width: 450px) {
  .invention {
    .sub-block {
      .sub-title {
        flex-direction: column;
        align-items: flex-start;

        .title-tips {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
